.chip {
  --chip-size: #{rem($button-base-size)};
  --chip-font-size: 1rem;
  --chip-color: white;
  --chip-bg: var(--bs-primary);

  min-width: var(--chip-size);
  width: var(--chip-size);
  height: var(--chip-size);
  aspect-ratio: 1/1;
  border-radius: rem(200px);
  background-color: var(--chip-bg);

  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: var(--chip-font-size);
  color: var(--chip-color);

  fstr-icon {
    z-index: 2;
  }
}

@each $name, $value in $button-sizes {
  .chip--#{$name} {
    --chip-size: #{rem($value)};
    --chip-font-size: #{map-get($font-sizes, 'body-' + $name)};
  }
}

@each $name, $color in map-merge($theme-colors, $button-themes) {
  .chip--#{$name} {
    --chip-bg: #{$color};
  }
}

// Custom overrides
.chip--light {
  --chip-color: black;
}

.chip--xs .icon {
  stroke-width: 4px;
}

.chip--light-primary {
  --chip-color: var(--bs-primary);
}
.chip--light-secondary {
  --chip-color: var(--bs-secondary);
}

.chip--primary-100 {
  --chip-color: var(--bs-primary);
}
.chip--secondary-100 {
  --chip-color: var(--bs-secondary);
}
