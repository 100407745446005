body {
  font-optical-sizing: auto;
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
}

%heading {
  word-break: break-word;
  overflow-wrap: break-word;
  hyphens: auto; // For this to work html lang attribute must be set to the correct language
  font-optical-sizing: auto;
}

h1,
.h1 {
  @extend %heading;
}

h2,
.h2 {
  @extend %heading;
}

h3,
.h3 {
  @extend %heading;
}

h4,
.h4 {
  @extend %heading;
}

h5,
.h5 {
  @extend %heading;
}

h6,
.h6 {
  @extend %heading;
}

img {
  display: inline-block;
}
