table {
  margin-top: rem(20px);
  margin-bottom: 1em;
  font-size: rem(14px);
  width: 100%;

  @include media-breakpoint-down(md) {
    width: calc(100% + #{$grid-gutter-width});
    margin-right: $grid-gutter-width;
  }

  overflow: hidden;
  overflow-x: auto;
  display: block;

  @extend .custom-scrollbar;

  th {
    background: var(--bs-primary);
    color: white;
    font-weight: bold;
    text-align: left;
    padding: 0.7rem 0.5rem;
  }

  tr td:not(:last-child) {
    border-right: 1px solid var(--bs-gray-200);
  }

  tr th:not(:last-child) {
    border-right: 1px solid var(--bs-gray-200);
  }

  tr td {
    padding: map-get($spacers, 2);
    min-width: 180px;

    &.fr-thick {
      font-weight: bold;
    }
    &.fr-highlighted {
      border: 2px solid var(--bs-secondary);
    }
  }

  tr:nth-child(even) td {
    background: var(--bs-gray-100);
  }
}
