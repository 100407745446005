// TODO: make it possible to use custom colors, and fallback to generated tints
@mixin tint-css-variables($color: 'primary', $theme: 'light') {
  @if $theme == 'dark' {
    @for $i from 1 through 9 {
      @if $i < 5 {
        --bs-#{$color}-#{$i * 100}: color-mix(in srgb, var(--bs-#{$color}) #{((20% * $i))}, black);
      }
      @if $i == 5 {
        --bs-#{$color}-500: var(--bs-#{$color});
      }
      @if $i > 5 {
        --bs-#{$color}-#{$i * 100}: color-mix(in srgb, var(--bs-#{$color}) #{100% - (20% * ($i - 5))}, white);
      }
    }
  } @else {
    @for $i from 1 through 9 {
      @if $i < 5 {
        --bs-#{$color}-#{$i * 100}: color-mix(in srgb, var(--bs-#{$color}) #{((20% * $i))}, white);
      }
      @if $i == 5 {
        --bs-#{$color}-500: var(--bs-#{$color});
      }
      @if $i > 5 {
        --bs-#{$color}-#{$i * 100}: color-mix(in srgb, var(--bs-#{$color}) #{100% - (20% * ($i - 5))}, black);
      }
    }
  }
}
