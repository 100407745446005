.content-page {
  --media-header-offset: 1rem;

  max-width: 100vw;
  overflow: hidden;

  @include media-breakpoint-down(md) {
    .lead {
      font-size: 1rem;
    }
  }

  &.set--media {
    --media-header-offset: 4rem;

    @include media-breakpoint-up(lg) {
      --media-header-offset: 8rem;
    }
  }

  &__main {
    padding-top: calc(var(--media-header-offset));

    @include media-breakpoint-up(lg) {
      padding-top: calc(var(--media-header-offset) - 2rem);
    }

    @include media-breakpoint-down(md) {
      margin-top: calc(var(--media-header-offset) * 0.8);
    }
  }
}
