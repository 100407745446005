@keyframes pulse {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.3;
  }

  100% {
    transform: translate(-50%, -50%) scale(2);
    opacity: 0;
  }
}

.chip--pulse {
  position: relative;

  &::after,
  &::before {
    @include abs(50%, null, null, 50%);
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
    border-radius: 50%;
    z-index: 1;
    background-color: var(--chip-bg);
    content: '';
    width: var(--chip-size);
    height: var(--chip-size);
  }

  &::before {
    animation: pulse 2s infinite;
    animation-fill-mode: forwards;
  }

  &::after {
    animation: pulse 2s 0.6s infinite;
    animation-fill-mode: forwards;
  }
}
