.button.button-link-chip {
  --button-icon-inset: 0;
  --button-icon-size: 1.5em;
  // --button-size: auto;

  background: transparent;
  color: var(--bs-dark);
  // padding: 0;
  border-radius: 0;
  gap: 1.5ex;

  @media (hover: hover) {
    &:hover {
      fstr-icon {
        &::before {
          animation: pulse 2s infinite;
        }

        &::after {
          animation: pulse 2s 0.3s infinite;
        }
      }
    }
  }

  fstr-icon {
    width: calc(var(--button-icon-size) * 1.8);
    height: calc(var(--button-icon-size) * 1.8);
    border-radius: calc(var(--button-icon-size) * 1.8);
    background-color: color-mix(in srgb, var(--button-bg), white 55%);
    color: var(--button-color, inherit);
    align-items: center;
    justify-content: center;
    transition: background-color 200ms ease-in-out;
    position: relative;

    &::after,
    &::before {
      @include abs(50%, null, null, 50%);
      transform: translate(-50%, -50%) scale(1);
      opacity: 0;
      border-radius: 50%;
      z-index: 1;
      content: '';
    }

    &::before {
      width: calc(var(--button-icon-size) * 2);
      height: calc(var(--button-icon-size) * 2);
      border: 4px solid color-mix(in srgb, var(--button-bg), white 65%);
    }

    &::after {
      width: calc(var(--button-icon-size) * 2.2);
      height: calc(var(--button-icon-size) * 2.2);
      border: 4px solid color-mix(in srgb, var(--button-bg), white 75%);
    }

    .icon {
      width: 50%;
      height: 50%;
      position: relative;
      z-index: 4;
    }
  }

  @media (hover: hover) {
    &:hover {
      background: transparent;
      color: var(--button-hover-bg);

      fstr-icon {
        background-color: var(--button-hover-bg);
      }
    }
  }

  &.button--dark {
    fstr-icon {
      background-color: var(--bs-light);
      color: var(--bs-dark);
    }
  }

  &.button--light {
    color: var(--bs-light);
    fstr-icon {
      background-color: var(--bs-dark);
      color: var(--bs-light);
    }
  }
}

@keyframes pulse {
  0% {
    transform: translate(-50%, -50%) scale(0.8);
    opacity: 0;
  }
  50% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.8;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
}
