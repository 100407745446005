.accordion {
  &__title {
    .accordion__button {
      display: flex;
      align-items: center;
      text-align: left;
      width: 100%;
      justify-content: stretch;

      .icon {
        transition: transform 0.3s ease;

        .accordion--open & {
          transform: rotate(180deg);
        }
      }
    }
  }

  &__content {
    max-height: 0;
    overflow: hidden;

    transition: max-height 0.6s cubic-bezier(0, 1, 0, 1);

    .accordion--open & {
      max-height: 1000px;
      transition: max-height 0.5s ease-in-out;
    }
  }
}
