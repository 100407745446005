.app {
  position: relative;
  max-width: 100vw;
  overflow: hidden;
  min-height: 100vh;
  //min-height: calc((var(--vh, 1vh) * 100) - calc(var(--topbar-height) + var(--topbar-upper-height) + 3rem));
}

app-topbar {
  position: relative;
  z-index: 1000;
  width: 100%;
  display: block;
}

.app__inner {
  min-height: 100vh;
  min-height: calc((var(--vh, 1vh) * 100) - calc(var(--topbar-height) + var(--topbar-upper-height) + 3rem));
}
