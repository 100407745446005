body {
  --mdc-filled-text-field-container-color: #{$input-bg};
  --mdc-filled-text-field-input-text-color: var(--bs-body-color);
  --mdc-filled-text-field-label-text-color: var(--bs-primary);
  --mdc-filled-text-field-input-text-placeholder-color: var(--bs-secondary-color);

  --mat-select-panel-background-color: var(--bs-body-bg);
  --mat-option-label-text-color: var(--bs-body-color);
  --mat-select-enabled-arrow-color: var(--bs-body-color);

  --mat-autocomplete-background-color: var(--bs-body-bg);

  --mat-menu-container-color: var(--bs-body-bg);
  --mat-menu-item-label-text-color: var(--bs-body-color);
  --mat-menu-item-icon-color: var(--bs-body-color);

  --mdc-filled-text-field-active-indicator-color: var(--bs-primary);
}

.material-symbols-rounded {
  font-variation-settings:
    'FILL' 0,
    'wght' 300,
    'GRAD' 0,
    'opsz' 24;
}
