.mat-mdc-form-field.mat-form-field-appearance-fill {
  --mat-form-field-focus-state-layer-opacity: 0;
  --mdc-filled-text-field-container-shape: 10px;
  --mat-form-field-filled-with-label-container-padding-top: 20px;
  --mdc-filled-text-field-label-text-color: var(--bs-tertiary-color);

  &.mat-focused {
    .mdc-text-field--filled {
      border-color: var(--bs-primary);
    }
  }

  .mdc-floating-label--float-above {
    transform: translateY(-85%) scale(0.65);
  }
}

.mdc-text-field.mdc-text-field--filled {
  border-radius: var(--mdc-filled-text-field-container-shape);
  border: 1px solid transparent;

  .mdc-line-ripple {
    display: none;
  }
}
