.button.button-outline {
  background: transparent;
  color: var(--button-bg);
  border-color: var(--button-bg);

  fstr-icon {
    color: inherit;
  }

  @media (hover: hover) {
    &:hover {
      background: color-mix(in srgb, var(--button-bg) #{$button-outline-hover-opacity-amount}, transparent);
    }
  }
}
